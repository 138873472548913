import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import breakpoint from "utils/breakpoints/"

// Layouts
import Layout from "layouts/layout-primary"

// Components
import Container from "components/container/"
import { graphql } from "gatsby"
import SEO from "components/seo"
import { colors } from "utils/variables"

const StyledLegalPage = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .h1,
  .h2,
  .h3,
  .h4 {
    margin-bottom: 1rem;
  }

  p {
    margin: 1em 0;
  }

  a {
    color: ${colors.turquoise__primary};
    text-decoration: underline;

    &:hover {
      color: ${colors.silver__dark};
    }
  }

  ul {
    padding-left: 16px;
    margin: 2em 0;
    list-style-type: disc;
  }
`

const LegalPage = (props) => {
  const {
    data: {
      contentfulLegalPages: { title, content },
    },
  } = props

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="h1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3">{children}</h3>
      ),
    },
  }

  return (
    <Layout>
      <SEO title={`${title} | Arena Analytics`} />
      <StyledLegalPage>
        <Container>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8">
              <h1 className="h1 maxi text-center mb-5">{title}</h1>
              {renderRichText(content, renderOptions)}
            </div>
          </div>
        </Container>
      </StyledLegalPage>
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    contentfulLegalPages: PropTypes.shape({
      updatedAt: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.shape({
        raw: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}

export default LegalPage

export const query = graphql`
  query ($id: String) {
    contentfulLegalPages(id: { eq: $id }) {
      ...LegalPage
    }
  }
`
